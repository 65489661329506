<template>
    <div class="Loginbl border-box">
        <img class="LogoImg margin-auto" src="../../../public/img//Login/logo_bl.png">
        <div :class="{'ShowAnimate':!ShowAnimate}" class="margin-auto flex column border-box">
            <div v-for="(item,index) in Placeholder" :key="index" class="input-box flex content-center">
                <img :src="'./img/Login/' + ImgArray[index] +'.png'">
                <input v-model="LoginParmas[TypeArray[index]]" :placeholder="item" :type="TypeArray[index]"
                       class="color333 rfont20 border-box">
            </div>
            <div class="flex content-center btn-box text-center rfont32">
                <div @click="GameLoginFn" class="pointer">登录</div>
                <div @click="FreeLogin" class="pointer colorfff">试玩</div>
            </div>
            <div v-if="IsRegister" @click="SetStore({Prop: 'ShowRegister',Val:true})"
                 class="font32 mat15 register-btn text-center margin-auto pointer">
                注册
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {GameLogin, QuickLogin, PlayerDemo, Instance} from '../../Api';

    export default {
        name: 'LoginInbl',
        computed: {...mapState(['SocketFn', 'DefaultConfig', 'ReInit'])},
        props: {
            'ShowAnimate': {
                default() {
                    return true
                }, type: Boolean
            }
        },
        data() {
            return {
                CallBack: ['CallSocketLoginFn'],
                Placeholder: ['请输入账号', '请输入密码'],
                ImgArray: ['login_user', 'login_mima'],
                TypeArray: ['text', 'password'],
                LoginParmas: {'text': '', 'password': ''},
                Isfree: false, //是否是玩
                IsRegister: false,
            }
        },
        created() {
            this.$nextTick(() => {
                this.Init();
            });
        },
        watch: {
            'DefaultConfig': {
                handler(n, o) {
                    this.LoginParmas.text = n.DefaultName || "";
                    this.LoginParmas.password = n.DefaultsPassword || "";
                }, deep: true, immediate: true
            },
            'ReInit': {
                handler(n, o) {
                    if (n) {
                        this.ReBaseUrl();
                        this.SetStore({
                            Prop: 'ReInit',
                            Val: false
                        });
                    }
                }
            }
        },
        methods: {
            Init() {
                this.IsRegister = this.$ReConfig.InviteCode ? true : false; //是否显示注册按钮
                this.ReBaseUrl();
                this.Auto();
            },
            Auto() { //是否自动登录
                let query = this.$route.query;
                sessionStorage.Defaultroom = this.ToString({
                    GameType: query.gameType || 0,
                    HallType: query.hallType || 0
                });
                if (query.isdemo && query.isdemo !== '0') { //免费试玩
                    this.FreeLogin();
                } else if (query.launchtoken) {  //快速登陆
                    this.QuickLoginFn(query.launchtoken);
                }
            },
            QuickLoginFn(launchtoken) {  //自动登录
                QuickLogin({launchtoken}).then((Resp) => {
                    sessionStorage.LaunchToken = true;  //是自动登录过来的
                    this.CallLoginFn(Resp);
                });
            },
            ForRegister(DefaultName, DefaultsPassword) {  //为注册完成编写的自动登录
                this.LoginParmas.text = DefaultName;
                this.LoginParmas.password = DefaultsPassword;
                this.GameLoginFn();
            },
            ReBaseUrl() {
                Instance.defaults.baseURL = this.$ReConfig.Api || 'http://182.61.43.84:9092'; //需要重新配置axios的请求地址
            },
            GameLoginFn() {
                this.ReBaseUrl();
                let {text, password: Password} = this.LoginParmas;
                if (text === '' || Password === '') {
                    this.Toast('2', '账号或密码错误');
                    return;
                }
                GameLogin({
                    'username': text,
                    'password': Password
                }).then((Resp) => {
                    this.Isfree = false;
                    this.CallLoginFn(Resp);
                });
            },
            CallLoginFn(Resp) {  //登录、试玩接口的回调
                if (Resp.code !== 0) return;
                let Fn = () => {
                    if (!this.Isfree) {
                        sessionStorage.Authorization = Resp.data.token;  //缓存tokend
                        this.SocketLogin(Resp.data.sign, Resp.data.timestamp, Resp.data.username);
                    } else {  //免费试玩不需要请求个人中心
                        sessionStorage.removeItem('Authorization');
                        this.SocketLogin(Resp.data.sign, Resp.data.timestamp, Resp.data.username);
                    }
                };
                this.SocketApi.InitSock(Resp.data.serverAddr).then(() => {  //链接ws
                    Instance.defaults.baseURL = Instance.defaults.baseURL + '/gameApi';  //第一次修改请求地址
                    Fn();
                });
            },
            SocketLogin(Sign, Timestamp, Freename) {
                let {text} = this.LoginParmas;
                this.SocketApi.SendSock([this.stype.Center, this.cmd.SignLogin, 0, {
                    "sign": Sign,
                    "timestamp": Timestamp,
                    "username": Freename || text,
                }], 'CallSocketLoginFn');
            },
            CallSocketLoginFn(Resp) {
                this.SetStore({
                    Prop: 'UserInfo',
                    Val: Resp
                });
                this.Toast("1", "登录成功", {
                    Time: 3, CallBack: () => {
                        this.$router.replace("GameHall"); //直接重定向到游戏中心 避免手动返回到登陆页面
                    }
                });
            },
            FreeLogin() {  //试玩
                PlayerDemo({
                    player: "888",
                    timestamp: new Date().getTime()
                }).then((Resp) => {
                    this.Isfree = true;
                    this.CallLoginFn(Resp);
                });
            },
        }
    }
</script>

<style lang="less">
    html {
        background: url("../../../public/img/Login/bg_bl.png") no-repeat top center/cover;
    }

    .LogoImg {
        width: 2.44rem;
    }

    .ShowAnimate {
        transition: .5s;
        opacity: 1 !important;
        transform: rotateY(0deg) !important;
    }

    .Loginbl {
        padding-top: .45rem;

        > div {
            width: 6.95rem;
            /*height: 320px;*/
            background: url("../../../public/img/Login/login_k_bg.png") no-repeat center center/100% 100%;
            margin-top: .3rem;
            padding: .3rem 0;
            transform: rotateY(90deg);
            opacity: 0;

            .input-box {
                margin-bottom: .35rem;

                input {
                    width: 4.5rem;
                    border-radius: .12rem;
                    padding: 0 .15rem;
                    margin-left: .1rem;
                    background-color: #ffffff;
                }
            }

            .btn-box {
                div {
                    width: 2.2rem;
                    height: .7rem;
                    line-height: .7rem;

                    &:first-child {
                        color: #69300f;
                        background: url("../../../public/img/Login/dl_dl_btn.png") no-repeat center center/cover;
                        margin-right: .6rem;

                        &:hover {
                            background: url("../../../public/img/Login/dl_dl_btn_hover.png") no-repeat center center/cover;
                            color: #ffffff;
                        }
                    }

                    &:last-child {
                        background: url("../../../public/img/Login/dl_sw_btn.png") no-repeat center center/cover;

                        &:hover {
                            background: url("../../../public/img/Login/dl_sw_btn_hover.png") no-repeat center center/cover;
                        }
                    }
                }
            }

            .register-btn {
                width: 5rem;
                height: .7rem;
                line-height: .7rem;
                background: url("../../../public/img/Login/dl_zc_btn.png") no-repeat center center/ 100% 100%;

                &:hover {
                    background: url("../../../public/img/Login/dl_zc_btn_hover.png") no-repeat center center/ 100% 100%;
                    color: #ffffff;
                }
            }
        }
    }
</style>
